import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/MJ/new/child.webp'
import Graph from '../components/graph'
import Mail from '../components/mail'
import MyVideo from '../components/video'
import More from '../components/more'

class Result extends React.Component {



  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;



  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "Child";
    return (
      <Layout>
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="Remains naive, avoids responsibility"/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>



            <h2>{name}</h2>
            
            <p>Beware the Innocent archetype, lurking within the depths of your psyche. This dangerous entity promises a false sense of security, tempting you with the allure of a perfect life without hardship. The Innocent appears as the trusting child within us, but do not be fooled by its innocent facade. This archetype is dependent and seeks safety at all costs, leading to a fear of abandonment that can consume you. Its supposed strength, trust and optimism, may lead to blindness towards your weaknesses and a dangerous dependence on others to fulfill your heroic tasks. If left unchecked, the Innocent archetype will slowly ruin your life and relationships, leaving you trapped in its false promises of a perfect world.

But beware, the Innocent archetype can also lead to a dangerous path of self-destruction if not properly integrated. The innocence and trust that once endeared them to others, can turn into a sense of entitlement and a refusal to take responsibility for their own actions. They may become blind to their own weaknesses and become dependent on others to fulfill their needs, leading to a life of disappointment and failed relationships. The Innocent archetype, left unchecked, can slowly eat away at one's sense of self, leading to a life of despair and isolation. To avoid falling into this trap, it is crucial to read and understand the e-book <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=innocent"}>"The Inner World of Archetypes: A Guide to Integrating the 12 Jungian Archetypes"</a> and take steps to integrate this archetype within yourself, before it takes control and ruins your life.
</p>
<More arch={name}/>
{this.state ? (
  <div>
    <Graph best={this.state.best}  data ={[
{

"id": this.state.names_arr[0],
"label": this.state.names_arr[0],
"value": this.state.vals_arr[0],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[1],
"label": this.state.names_arr[1],
"value": this.state.vals_arr[1],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[2],
"label": this.state.names_arr[2],
"value": this.state.vals_arr[2],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[3],
"label": this.state.names_arr[3],
"value": this.state.vals_arr[3],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[4],
"label": this.state.names_arr[4],
"value": this.state.vals_arr[4],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[5],
"label": this.state.names_arr[5],
"value": this.state.vals_arr[5],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[6],
"label": this.state.names_arr[6],
"value": this.state.vals_arr[6],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[7],
"label": this.state.names_arr[7],
"value": this.state.vals_arr[7],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[8],
"label": this.state.names_arr[8],
"value": this.state.vals_arr[8],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[9],
"label": this.state.names_arr[9],
"value": this.state.vals_arr[9],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[10],
"label": this.state.names_arr[10],
"value": this.state.vals_arr[10],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[11],
"label": this.state.names_arr[11],
"value": this.state.vals_arr[11],
"color": "hsl(336, 70%, 50%)"
}
]}/>

<Mail best={this.state.best}/>
<MyVideo/>
</div>




) : (
  <div><i>Take the test on the <a href="/">main page</a> to see your archetype breakdown.</i>


  <br/>
<MyVideo/>

  </div>
)}
          </section>

        </div>
      </Layout>
    )
  }
}

export default Result
